//全局弹框
import { ElMessage } from 'element-plus'

const popup = class{
	constructor(message, type="success") {
	    this.message = message
		this.type = type
	}
	
	message_popup(){
		ElMessage({
			message: this.message,
			type: this.type,
			duration: 3000
		})
	}
}

export default popup
