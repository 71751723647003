import {
	createApp
} from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
// import axios from 'axios'
// import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
//全局路由
import router from "./router/index.js"
//全局ECharts
import echarts from "./echarts/index.js"
//全局引入css样式
import "../css/path.css"
import "../css/login.css"
import "../css/index.css"

const app = createApp(App)
app.use(ElementPlus, {
	locale: zhCn
})
app.use(router)
//请求地址
import urls from "../api/mantaAPI.js"
app.config.globalProperties.$urls = urls
//请求方法
import request from "../api/request.js"
app.config.globalProperties.$request = request
//弹窗提示
import popup from "../config/popup.js"
app.config.globalProperties.$popup = popup
//vue-cookies
app.config.globalProperties.$cookies = VueCookies
//echarts
app.config.globalProperties.$echarts = echarts

app.mount('#app')
