import {
	createRouter,
	createWebHistory
} from "vue-router"

// 创建路由器
const routes = [{
		path: "/",
		name: "path",
		meta: {title: "ZHLY短信运营平台"},
		component: () => import( /* webpackChunkName:'Path' */ "@/page/path/Path.vue"),
		redirect: "/home",
		children: [{
				path: "/home",
				name: "home",
				meta: {title: "首页"},
				component: () => import( /* webpackChunkName:'Home' */ "@/page/path/Home.vue")
			},
			{
				path: "/moreProducts",
				name: "moreProducts",
				meta: {title: "了解更多产品"},
				component: () => import( /* webpackChunkName:'MoreProducts' */ "@/page/path/MoreProducts.vue")
			},
			{
				path: "/moreServe",
				name: "moreServe",
				meta: {title: "了解更多服务"},
				component: () => import( /* webpackChunkName:'MoreServe' */ "@/page/path/MoreServe.vue")
			},
			{
				path: "/appDown",
				name: "appDown",
				meta: {title: "应用下载"},
				component: () => import( /* webpackChunkName:'AppDown' */ "@/page/path/product/AppDown.vue")
			},
			{
				path: "/captchaSMS",
				name: "captchaSMS",
				meta: {title: "验证码短信"},
				component: () => import( /* webpackChunkName:'CaptchaSMS' */ "@/page/path/product/CaptchaSMS.vue")
			},
			{
				path: "/marketingSMS",
				name: "marketingSMS",
				meta: {title: "营销短信"},
				component: () => import( /* webpackChunkName:'MarketingSMS' */ "@/page/path/product/marketingSMS.vue")
			},
			{
				path: "/informSMS",
				name: "informSMS",
				meta: {title: "通知短信"},
				component: () => import( /* webpackChunkName:'InformSMS' */ "@/page/path/product/informSMS.vue")
			},
			{
				path: "/ndFileSMS",
				name: "ndFileSMS",
				meta: {title: "二路短信"},
				component: () => import( /* webpackChunkName:'NdFileSMS' */ "@/page/path/product/ndFileSMS.vue")
			},
			{
				path: "/whatsappSMS",
				name: "whatsappSMS",
				meta: {title: "whatsapp短信"},
				component: () => import( /* webpackChunkName:'WhatsappSMS' */ "@/page/path/product/whatsappSMS.vue")
			},
			{
				path: "/captchaIVR",
				name: "captchaIVR",
				meta: {title: "验证码语音"},
				component: () => import( /* webpackChunkName:'CaptchaIVR' */ "@/page/path/product/captchaIVR.vue")
			},
			{
				path: "/marketingIVR",
				name: "marketingIVR",
				meta: {title: "营销语音"},
				component: () => import( /* webpackChunkName:'MarketingIVR' */ "@/page/path/product/marketingIVR.vue")
			},
			{
				path: "/hlr",
				name: "hlr",
				meta: {title: "HLR"},
				component: () => import( /* webpackChunkName:'Hlr' */ "@/page/path/product/hlr.vue")
			},
			{
				path: "/aboutUs",
				name: "aboutUs",
				meta: {title: "关于我们"},
				component: () => import( /* webpackChunkName:'AboutUs' */ "@/page/path/aboutUs.vue")
			},
			{
				path: "/apiDoc",
				name: "apiDoc",
				meta: {title: "API文档"},
				component: () => import( /* webpackChunkName:'ApiDoc' */ "@/page/path/apiDoc.vue")
			},
			{
				path: "/login",
				name: "login",
				meta: {title: "登录"},
				component: () => import( /* webpackChunkName:'Login' */ "@/page/login/login.vue")
			},
			{
				path: "/register",
				name: "register",
				meta: {title: "注册"},
				component: () => import( /* webpackChunkName:'Register' */ "@/page/login/register.vue")
			},
			{
				path: "/changePassword",
				name: "changePassword",
				meta: {title: "修改密码"},
				component: () => import( /* webpackChunkName:'ChangePassword' */ "@/page/login/changePassword.vue")
			},
		]
	},
	{
		path: "/index",
		name: "index",
		component:()=>import(/* webpackChunkName:'Index' */ "@/page/index/index/index.vue"),
		redirect:"/customerInformation",
		children:[
			{
				path: "/customerInformation",
				name: "customerInformation",
				meta: {title: "客户管理"},
				component:()=>import(/* webpackChunkName:'CustomerInformation' */ "@/page/index/customerInformation/customerInformation.vue")
			},
			{
				path: "/customerAccount",
				name: "customerAccount",
				meta: {title: "客户账号管理"},
				component:()=>import(/* webpackChunkName:'CustomerAccount' */ "@/page/index/customerAccount/customerAccount.vue")
			},
			{
				path: "/customerPrice",
				name: "customerPrice",
				meta: {title: "客户价格管理"},
				component:()=>import(/* webpackChunkName:'CustomerPrice' */ "@/page/index/customerPrice/customerPrice.vue")
			},
			{
				path: "/customerChannel",
				name: "customerChannel",
				meta: {title: "客户通道管理"},
				component:()=>import(/* webpackChunkName:'CustomerChannel' */ "@/page/index/customerChannel/customerChannel.vue")
			},
			{
				path: "/availableChannels",
				name: "availableChannels",
				meta: {title: "客户可用通道管理"},
				component:()=>import(/* webpackChunkName:'AvailableChannels' */ "@/page/index/customerChannel/availableChannels.vue")
			},
			{
				path: "/availableChannelsAdd",
				name: "availableChannelsAdd",
				meta: {title: "客户可用通道添加"},
				component:()=>import(/* webpackChunkName:'AvailableChannelsAdd' */ "@/page/index/customerChannel/availableChannelsAdd.vue")
			},
			{
				path: "/countriesRegions",
				name: "countriesRegions",
				meta: {title: "国家地区"},
				component:()=>import(/* webpackChunkName:'CountriesRegions' */ "@/page/index/countriesRegions/countriesRegions.vue")
			},
			{
				path: "/nationalOperator",
				name: "nationalOperator",
				meta: {title: "运营商管理"},
				component:()=>import(/* webpackChunkName:'CarrierManagement' */ "@/page/index/countriesRegions/nationalOperator/nationalOperator.vue")
			},
			{
				path: "/themRoughly",
				name: "themRoughly",
				meta: {title: "号段"},
				component:()=>import(/* webpackChunkName:'ThemRoughly' */ "@/page/index/countriesRegions/nationalOperator/themRoughly/themRoughly.vue")
			},
			{
				path: "/currencyManagement",
				name: "currencyManagement",
				meta: {title: "货币管理"},
				component:()=>import(/* webpackChunkName:'CurrencyManagement' */ "@/page/index/currencyManagement/currencyManagement.vue")
			},
			{
				path: "/ratePlan",
				name: "ratePlan",
				meta: {title: "费率计划管理"},
				component:()=>import(/* webpackChunkName:'RatePlan' */ "@/page/index/ratePlan/ratePlan.vue")
			},
			{
				path: "/ratePlanPrice",
				name: "ratePlanPrice",
				meta: {title: "费率计划价格"},
				component:()=>import(/* webpackChunkName:'RatePlanPrice' */ "@/page/index/ratePlan/RatePlanPrice.vue")
			},
			{
				path: "/sendRecord",
				name: "sendRecord",
				meta: {title: "发送记录"},
				component:()=>import(/* webpackChunkName:'SendRecord' */ "@/page/index/sendRecord.vue")
			},
			{
				path: "/priceLog",
				name: "priceLog",
				meta: {title: "国家价格更改记录"},
				component:()=>import(/* webpackChunkName:'PriceLog' */ "@/page/index/priceLog.vue")
			},
			{
				path: "/recordsCosts",
				name: "recordsCosts",
				meta: {title: "客户账单明细"},
				component:()=>import(/* webpackChunkName:'RecordsCosts' */ "@/page/index/recordsCosts.vue")
			},
			{
				path: "/supplierBD",
				name: "supplierBD",
				meta: {title: "供应商账单明细"},
				component:()=>import(/* webpackChunkName:'SupplierBD' */ "@/page/index/SupplierBD.vue")
			},
			{
				path: "/totalBD",
				name: "totalBD",
				meta: {title: "总账单明细"},
				component:()=>import(/* webpackChunkName:'TotalBD' */ "@/page/index/TotalBD.vue")
			},
			{
				path: "/payRank",
				name: "payRank",
				meta: {title: "客户充值记录"},
				component:()=>import(/* webpackChunkName:'PayRank' */ "@/page/index/payRank.vue")
			},
			{
				path: "/lookPrice",
				name: "lookPrice",
				meta: {title: "客户价格管理"},
				component:()=>import(/* webpackChunkName:'LookPrice' */ "@/page/index/lookPrice/lookPrice.vue")
			},
			{
				path: "/accountManagement",
				name: "accountManagement",
				meta: {title: "账号管理"},
				component:()=>import(/* webpackChunkName:'AccountManagement' */ "@/page/index/accountManagement/accountManagement.vue")
			},
			{
				path: "/channelManagement",
				name: "channelManagement",
				meta: {title: "通道管理"},
				component:()=>import(/* webpackChunkName:'ChannelManagement' */ "@/page/index/channelManagement/channelManagement.vue")
			},
			{
				path: "/channelManagementAdd",
				name: "channelManagementAdd",
				meta: {title: "通道添加"},
				component:()=>import(/* webpackChunkName:'ChannelManagementAdd' */ "@/page/index/channelManagement/channelManagementAdd.vue")
			},
			{
				path: "/channelManagementUpdate",
				name: "channelManagementUpdate",
				meta: {title: "通道修改"},
				component:()=>import(/* webpackChunkName:'ChannelManagementUpdate' */ "@/page/index/channelManagement/channelManagementUpdate.vue")
			},
			{
				path: "/stateDetails",
				name: "stateDetails",
				meta: {title: "通道连接状态"},
				component:()=>import(/* webpackChunkName:'stateDetails' */ "@/page/index/channelManagement/stateDetails.vue")
			},
			{
				path: "/channelOperator",
				name: "channelOperator",
				meta: {title: "运营商管理"},
				component:()=>import(/* webpackChunkName:'CarrierManagement' */ "@/page/index/channelManagement/channelOperator.vue")
			},
			{
				path: "/choosingCarrier",
				name: "choosingCarrier",
				meta: {title: "运营商选择"},
				component:()=>import(/* webpackChunkName:'ChoosingCarrier' */ "@/page/index/channelManagement/choosingCarrier.vue")
			},
			{
				path: "/gatewayInfo",
				name: "gatewayInfo",
				meta: {title: "网关信息"},
				component:()=>import(/* webpackChunkName:'GatewayInfo' */ "@/page/index/gatewayInfo.vue")
			},
			{
				path: "/proxyInfo",
				name: "proxyInfo",
				meta: {title: "代理信息"},
				component:()=>import(/* webpackChunkName:'ProxyInfo' */ "@/page/index/proxyInfo.vue")
			},
			{
				path: "/channelPriceManagement",
				name: "channelPriceManagement",
				meta: {title: "通道价格管理"},
				component:()=>import(/* webpackChunkName:'ChannelPriceManagement' */ "@/page/index/channelPriceManagement/channelPriceManagement.vue")
			},
			{
				path: "/channelPriceLog",
				name: "channelPriceLog",
				meta: {title: "通道价格更改记录"},
				component:()=>import(/* webpackChunkName:'ChannelPriceLog' */ "@/page/index/channelPriceLog.vue")
			},
			{
				path: "/currencyExchange",
				name: "currencyExchange",
				meta: {title: "货币兑换率"},
				component:()=>import(/* webpackChunkName:'CurrencyExchange' */ "@/page/index/currencyExchange/currencyExchange.vue")
			},
			{
				path: "/priceLogTimedTask",
				name: "priceLogTimedTask",
				meta: {title: "国家价格更改任务"},
				component:()=>import(/* webpackChunkName:'PriceLogTimedTask' */ "@/page/index/priceLogTimedTask/priceLogTimedTask.vue")
			},
			{
				path: "/priceLogEmail",
				name: "priceLogEmail",
				meta: {title: "通道价格邮箱日志"},
				component:()=>import(/* webpackChunkName:'PriceLogEmail' */ "@/page/index/priceLogEmail/PriceLogEmail.vue")
			},
			{
				path: "/excelFormat",
				name: "excelFormat",
				meta: {title: "Excel格式"},
				component:()=>import(/* webpackChunkName:'ExcelFormat' */ "@/page/index/ExcelFormat/ExcelFormat.vue")
			},
			{
				path: "/role",
				name: "role",
				meta: {title: "角色身份管理"},
				component:()=>import(/* webpackChunkName:'Role' */ "@/page/index/role/Role.vue")
			},
			{
				path: "/menu",
				name: "menu",
				meta: {title: "菜单管理"},
				component:()=>import(/* webpackChunkName:'Menu' */ "@/page/index/menu/Menu.vue")
			},
		]
	},
	{
		path: "/serverPerformance",
		name: "serverPerformance",
		meta: {title: "服务器性能"},
		component:()=>import(/* webpackChunkName:'ServerPerformance' */ "@/page/ServerPerformance/ServerPerformance.vue")
	},
]

// 展示路由标签名
const scrollBehavior = (to, from ,savedPosition) => {
	if(savedPosition && to.meta.keepAlive){
		return savedPosition;
	}
	return{left: 0, top: 0}
}

// 配置路由工作模式
const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior
})

//路由守卫
router.beforeEach((to, from, next) => {
	//title显示路由名称
	if (to.meta.title) {
		document.title = to.meta.title;
	}
	//判断当前页面
	if (to.matched === null) {
		window.location.href = '/home'
	} else if (to.matched[0].path === "/index") {
		//index页面下，获取cookie，判断cookie中是否存在自定义的（id、admin、token、tokenExpiration）
		if (document.cookie.indexOf("id") === -1 && document.cookie.indexOf("admin") === -1 && document.cookie
			.indexOf("token") === -1 && document.cookie.indexOf("tokenExpiration") === -1 && to.path !== "/home"
			) {
			localStorage.setItem("menuPathIndex", JSON.stringify("/home"));
			localStorage.removeItem("username");
			localStorage.removeItem("menuIndex");
			window.location.href = '/home'
		}
	}
	next();
})

export default router
