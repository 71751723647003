import axios from 'axios'
import {
	ElMessageBox
} from 'element-plus'



/* ------------------------------------------------------- 配置 ------------------------------------------------------- */
//获取cookie
function getCookie(e, strCookie) {
	var t, a = new RegExp("(^| )" + e + "=([^;]*)(;|$)");
	if (t = strCookie.match(a)) {
		return unescape(t[2])
	} else {
		return null
	}
}



/* -------------------------------------------------- 创建axios实例 -------------------------------------------------- */
// 创建通用 axios 实例
let interceptSystem = axios.create({
	baseURL: '/apiSystem',
	responseType: "json",
	headers: {
		'Content-Type': 'application/json'
	},
})



/* -------------------------------------------------- 请求拦截器 -------------------------------------------------- */
// http拦截：axios请求发出之前
interceptSystem.interceptors.request.use(
	config => {
		//获取cookie
		let strCookie = document.cookie
		config.headers.Authorization = getCookie("token", strCookie)
		return config;
	}, error => {
		return Promise.reject(error);
	}
);



/* -------------------------------------------------- 响应拦截器 -------------------------------------------------- */
// http拦截：是在axios请求发出之后
interceptSystem.interceptors.response.use(
	response => {
		// return response
		let strCookie = document.cookie
		if (response.data.message !== undefined) {
			let msg = response.data.message
			if (msg == "认证失败，请重新登录" || msg == "token认证失败，请重新登录") {
				clearCache(strCookie)
				window.location.href = "/home"
			} else if (msg == "用户已在其他设备登录") {
				ElMessageBox.confirm(
					'用户已在其他设备登录，请重新登录',
					'提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
					}
				).then(() => {
					clearCache(strCookie)
					window.location.href = "/home"
				})
			} else if (msg == "token verification failed") {
				clearCache(strCookie)
				window.location.href = "/home"
			} else {
				return response
			}
		} else {
			return response
		}
	}, error => {
		return Promise.reject(error) //返回接口的错误信息
	}
)


//退出登录时清空缓存函数
function clearCache(strCookie) {
	localStorage.setItem("menuPathIndex", JSON.stringify("home"));
	localStorage.removeItem("username");
	localStorage.removeItem("menuIndex");
	let keys = strCookie.match(/[^ =;]+(?=\=)/g);
	for (let i = keys.length; i--;) {
		document.cookie = keys[i] +
			'=0;expires=Thu, 01 Jan 1970 00:00:00 UTC'
	}
}

export default interceptSystem
