import axios from 'axios'
import {
	ElMessageBox
} from 'element-plus'



/* ------------------------------------------------------- 配置 ------------------------------------------------------- */
//请求取消的变量
let cancel = null;
//获取cookie
function getCookie(e, strCookie) {
	var t, a = new RegExp("(^| )" + e + "=([^;]*)(;|$)");
	if (t = strCookie.match(a)) {
		return unescape(t[2])
	} else {
		return null
	}
}



/* -------------------------------------------------- 创建axios实例 -------------------------------------------------- */
// 创建通用 axios 实例
let instance = axios.create({
	baseURL: '/api',
	responseType: "json",
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*'
	},
})
// 刷新 token 的 axios 实例
let instanceRefeshToken = axios.create({
	baseURL: '/api',
	responseType: "json",
	headers: {
		'Content-Type': 'application/json',
	},
})
// token 过期后退出登录的 axios 实例
let instanceLogout = axios.create({
	baseURL: '/api',
	responseType: "json",
	headers: {
		'Content-Type': 'application/json',
	},
})



/* -------------------------------------------------- 请求拦截器 -------------------------------------------------- */
// http拦截：axios请求发出之前
instance.interceptors.request.use(
	config => {
		//获取cookie
		let strCookie = document.cookie
		//判断cookie是否存在
		if (strCookie.indexOf("id") != -1 && config.url.indexOf("vc.jpg") != 0) {
			//请求路径不是"logout"就在请求头加上token（正常点击退出登录）
			if (config.url.indexOf("logout") != 0) {
				config.headers.Authorization = getCookie("token", strCookie)
				// 刷新 token (当前时间（小），token有效时间（大），相差2h)
				if (Math.round(new Date()) >= (getCookie("tokenExpiration", strCookie) - 2 * 60 * 60 * 1000) && Math
					.round(new Date()) < getCookie("tokenExpiration", strCookie)) {
					new Promise((resolve, reject) => {
						instanceRefeshToken.get("/token:refresh").then(response => {
							resolve(response)
						}).catch(error => {
							//reject(error)
						})
					})
				} else if (Math.round(new Date()) > getCookie("tokenExpiration", strCookie)) {
					// token 过期请求发出后退出登录
					new Promise((resolve, reject) => {
						instanceLogout.get("/logout/" + getCookie("id", strCookie)).then(response => {
							resolve(response)
							//本地存储和cookie的各种删除
							clearCache(strCookie)
							window.location.href = "/home"
						}).catch(error => {
							//reject(error)
						})
					})
				}
			}
		}
		return config;
	}, error => {
		return Promise.reject(error);
	}
);
// 刷新 token 请求前拦截
instanceRefeshToken.interceptors.request.use(
	config => {
		//获取cookie
		let strCookie = document.cookie
		//判断cookie是否存在
		if (strCookie != "") {
			//取消上一次的请求
			if (cancel !== null) {
				cancel()
			}
			//取消请求的函数
			config.cancelToken = new axios.CancelToken(function (c) {
				cancel = c;
			})
			config.headers.Authorization = getCookie("token", strCookie)
		}
		return config;
	}, error => {
		return Promise.reject(error);
	}
);
// token 过期后退出登录的请求前拦截
instanceLogout.interceptors.request.use(
	config => {
		return config;
	}, error => {
		return Promise.reject(error);
	}
);



/* -------------------------------------------------- 响应拦截器 -------------------------------------------------- */
// http拦截：是在axios请求发出之后
instance.interceptors.response.use(
	response => {
		//return response
		let strCookie = document.cookie
		if (response.data.message !== undefined) {
			let msg = response.data.message
			if (msg == "认证失败，请重新登录" || msg == "token认证失败，请重新登录") {
				clearCache(strCookie)
				window.location.href = "/home"
			} else if (msg == "用户已在其他设备登录") {
				ElMessageBox.confirm(
					'用户已在其他设备登录，请重新登录',
					'提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
				}
				).then(() => {
					clearCache(strCookie)
					window.location.href = "/home"
				})
			} else if (msg == "token verification failed") {
				clearCache(strCookie)
				window.location.href = "/home"
			} else {
				return response
			}
		} else {
			return response
		}
	}, error => {
		return Promise.reject(error) //返回接口的错误信息
	}
)
// 刷新 token 响应后拦截
instanceRefeshToken.interceptors.response.use(
	response => {
		//获取cookie
		let strCookie = document.cookie
		//更新（替换）cookie 中的 token、tokenExpiration
		if (response.data.data != null) {
			let timestemp = new Date(response.data.data.tokenExpiration).toUTCString()
			document.cookie = "id=" + getCookie("id", strCookie) + ";expires=" + timestemp
			document.cookie = "admin=" + getCookie("admin", strCookie) + ";expires=" + timestemp
			document.cookie = "token=" + response.data.data.token + ";expires=" + timestemp
			document.cookie = "tokenExpiration=" + response.data.data.tokenExpiration + ";expires=" + timestemp
		}
		//初始化变量
		cancel = null;
		return response
	}, error => {
		return Promise.reject(error) //返回接口的错误信息
	}
)
// token 过期后退出登录的响应后拦截
instanceLogout.interceptors.response.use(
	response => {
		return response
	}, error => {
		return Promise.reject(error) //返回接口的错误信息
	}
)



//退出登录时清空缓存函数
function clearCache(strCookie) {
	localStorage.setItem("menuPathIndex", JSON.stringify("home"));
	localStorage.removeItem("username");
	localStorage.removeItem("menuIndex");
	let keys = strCookie.match(/[^ =;]+(?=\=)/g);
	for (let i = keys.length; i--;) {
		document.cookie = keys[i] +
			'=0;expires=Thu, 01 Jan 1970 00:00:00 UTC'
	}
}

export default instance
