import qs from "qs"

import instance from "./intercept.js"
import instanceExport from "./interceptExport.js"
import interceptSystem from "./interceptSystem.js"

const request = class {
	constructor(url, arg) {
		this.url = url
		this.arg = arg
	}
	
	/* ------------------------------ 普通的请求 ------------------------------ */
	/* get请求 */
	methodGet() {
		return new Promise((resolve, reject) => {
			instance.get((this.url), this.arg).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}
	/* post请求 */
	methodPost() {
		return new Promise((resolve, reject) => {
			instance.post((this.url), this.arg).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}
	/* put请求 */
	methodPut() {
		return new Promise((resolve, reject) => {
			instance.put((this.url), this.arg).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}
	/* delete请求 */
	methodDelete() {
		return new Promise((resolve, reject) => {
			instance.delete((this.url), this.arg).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}



	/* ------------------------------ 文件的请求 ------------------------------ */
	/* get请求 */
	methodGetExport() {
		return new Promise((resolve, reject) => {
			instanceExport.get((this.url), this.arg).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}
	/* post请求 */
	methodPostExport() {
		return new Promise((resolve, reject) => {
			instanceExport.post((this.url), this.arg).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}



	/* ------------------------------ 服务器性能数据 ------------------------------ */
	/* get请求 */
	methodGetSystem() {
		return new Promise((resolve, reject) => {
			interceptSystem.get((this.url), this.arg).then(response => {
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	}

}

export default request
