/* 
	此 intercept 专门用于导出的请求，将流转成文件格式
*/

import axios from 'axios'

function getCookie(e, strCookie) {
    var t, a = new RegExp("(^| )" + e + "=([^;]*)(;|$)");
    if (t = strCookie.match(a)) {
        return unescape(t[2])
    } else {
        return null
    }
}

// 创建通用axios配置
let instanceExport = axios.create({
	baseURL: '/api',
	headers: {
		// 'Content-Type': 'application/x-www-form-urlencoded',
		'Content-Type': 'application/json',
	},
	responseType: "blob",
})

// http拦截：axios请求发出之前
instanceExport.interceptors.request.use(
	config => {
		//获取cookie
		let strCookie = document.cookie
		//判断cookie是否存在
		if (strCookie != "") {
			config.headers.Authorization = getCookie("token", strCookie)
		}
		return config;
	}, error => {
		return Promise.reject(error);
	}
);

//  http拦截：是在axios请求发出之后
instanceExport.interceptors.response.use(
	response => {
		let fileName = decodeURIComponent(response.headers['content-disposition'])
		if(fileName == "undefined"){
			response.data.status = 400;
		} else {
			fileName = fileName.substring(fileName.indexOf("attachment;filename*=utf-8")+28, fileName.length)
			let url = window.URL.createObjectURL(response.data);
			let link = document.createElement('a');
			link.style.display = 'none';
			link.href = url;
			link.download = fileName;
			document.body.appendChild(link);
			link.click();
		}
		return response
	}, error => {
		return Promise.reject(error) //返回接口的错误信息
	}
)

export default instanceExport