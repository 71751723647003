const urls = class {
	static interfaces(id) {
		//excel格式接口
		const excelFormat = "excelFormat"; //excel格式查询（新增）
		const excelFormatID = "excelFormat/" + id; //excel格式修改（删除）

		//价格更改记录接口
		const logUserRegionPriceList = "logUserRegionPrice:list"; //查询价格更改记录

		//供应商账单明细接口
		const logChannelPriceCost = "logChannelPriceCost"; //根据条件查询所有费用信息
		const logChannelPriceCostCost = "logChannelPriceCost:cost"; //根据条件查询总费用和总数
		const logChannelPriceCostExport = "logChannelPriceCost/export"; //根据条件导出

		//发送记录接口
		const sendRecord = "sendRecord"; //根据条件查询发送记录

		//国家价格导入导出接口
		const regionPriceExportDownload = "regionPriceExport/download"; //国家价格导入模板下载
		const regionPriceExportExport = "regionPriceExport/export"; //国家价格导出
		const regionPriceExportImport = "regionPriceExport/import:" + id; //国家价格导入

		//国家价格接口
		const regionPrice = "regionPrice"; //国家价格添加（修改）
		const regionPriceList = "regionPrice:list/" + id; //根据id查询国家价格
		const regionPriceRefresh = "regionPrice:refresh"; //国家价格同步
		const regionPriceID = "regionPrice/" + id; //根据id查询国家价格（删除）
		const regionPricePlanIdPreviewMail = "regionPrice/" + id + "/previewMail"; //预览发送邮件
		const regionPricePlanIdSelectSendMail = "regionPrice/" + id + "/selectSendMail"; //发送邮件（批量发送）
		const regionPricePlanIdSendMail = "regionPrice/" + id + "/sendMail"; //发送邮件（全部发送）
		const regionPriceAllPlanRegionPrice = "regionPrice/allPlanRegionPrice"; //查询所有费率计划的国家价格
		const regionPriceDeleteBatch = "regionPrice/deleteBatch"; //批量删除国家价格

		/* 国家接口 */
		const region = "region"; //根据条件查询国家信息（添加）
		const regionExport = "region:export"; //导出国家、运营商和号段信息
		const regionImport = "region:import"; //导入国家、运营商和号段信息
		const regionRefresh = "region:refresh"; //国家同步
		const regionID = "region/" + id; //根据id查询国家信息（修改，删除）
		const regionRegionIdMCC = "region/" + id + "/mcc"; //根据国家查询MCC
		const regionNoPrice = "region/noPrice/" + id; //根据费率计划id查询未设置价格的国家

		/* 国家费率计划接口 */
		const regionPricePlan = "regionPricePlan"; //费率计划添加
		const regionPricePlanList = "regionPricePlan:list"; //根据条件查询费率计划
		const regionPricePlanID = "regionPricePlan/" + id; //根据id查询费率计划（修改，删除）
		const regionPricePlanCurrency = "regionPricePlan/currency/" + id; //根据货币类型查询费率计划
		
		/* 定时任务-国家价格接口 */
		const timingTaskRegionPrices = "timingTaskRegionPrices"; //国家价格定时任务查询
		const timingTaskRegionPricesID = "timingTaskRegionPrices/" + id; //根据国家价格定时任务id取消任务
		const timingTaskRegionPricesGetDetailsById = "timingTaskRegionPrices/getDetailsById/" + id; //根据国家价格定时任务id查看详情
		
		/* 客户价格管理接口 */
		const userAndRegionPrice = "userAndRegionPrice"; //客户价格管理添加
		const userAndRegionPriceList = "userAndRegionPrice:list"; //根据条件查询客户价格
		const userAndRegionPriceID = "userAndRegionPrice/" + id; //根据id查询客户价格（修改，删除）
		
		/* 客户管理接口 */
		const group = "group"; //客户添加
		const groupList = "group:list"; //根据条件查询客户
		const groupID = "group/" + id; //根据id查询客户（修改，删除）
		const groups = "groups"; //客户名称下拉数据
		
		/* 总账单明细 */
		const billDetails = "billDetails"; //根据条件查询总账单明细
		const billDetailsCost = "billDetails:cost"; //查询总费用和总数
		const billDetailsExport = "billDetails/export"; //导出
		
		/* 客户账单明细接口 */
		const logCustomerPriceCost = "logCustomerPriceCost"; //根据条件查询客户账单
		const logCustomerPriceCostCost = "logCustomerPriceCost:cost"; //客户账单总费用
		const logCustomerPriceCostExport = "logCustomerPriceCost/export"; //导出
		
		/* 客户账号充值记录接口 */
		const userRechargeLog = "userRechargeLog"; //根据条件查询客户账号充值记录
		
		/* 客户账号管理接口 */
		const groupUsers = "groupUsers"; //客户账号管理table
		const user = "user"; //客户账号添加（客户账号下拉框）
		const userID = "user/" + id; //根据id查询客户账号（修改，删除）
		const userPassword = "user/password"; //客户账号修改密码
		const userRecharge = "user/recharge"; //客户账号充值
		const userRechargePassword = "user/rechargePassword"; //客户账号重置密码
		const usersID = "users/" + id; //根据客户查询客户账号
		
		/* 客户通道接口 */
		const userChannels = "userChannels"; //根据条件查询客户通道（添加）
		const userChannelsRefresh = "userChannels:refresh"; //客户通道同步
		const userChannelsID = "userChannels/" + id; //根据id查询客户通道（修改）
		const userChannelsDeletes = "userChannels/deletes"; //删除
		const userChannelsGetUserConnectedRegionID = "userChannels/getUserConnectedRegion/" + id; //查询客户通道已接入国家
		const userChannelsGetUserNotConnectedRegionID = "userChannels/getUserNotConnectedRegion/" + id; //查询客户通道未接入国家
		const userChannelsUserChannelId = "userChannels/" + id; //根据ID查询可用通道信息
		
		/* 版本信息接口 */
		const version = "version"; //获取各服务版本信息
		
		/* 登录接口 */
		const login = "login"; //登录
		const logout = "logout/" + id; //退出登录
		const tokenRefresh = "token:refresh"; //刷新短令牌
		
		/* 账号管理接口 */
		const account = "account"; //根据条件查询账号（添加）
		const accountID = "account/" + id; //根据id查询账号（修改，删除）
		const accountPassword = "account/password"; //账号管理修改密码
		const accountReset = "account/reset:" + id; //账号管理重置密码
		
		/* 货币兑换率接口 */
		const currencyExchanges = "currencyExchanges"; //根据条件查询货币兑换率（添加）
		const currencyExchangesID = "currencyExchanges/" + id; //修改（删除）
		
		/* 货币接口 */
		const currency = "currency"; //根据条件查询货币（添加）
		const currencyID = "currency/" + id; //根据id查询货币（修改，删除）
		
		/* 运营商号段接口 */
		const operatorAndNDCs = "operatorAndNDCs"; //添加
		const operatorAndNDCsID = "operatorAndNDCs/" + id; //根据id查询运营商号段（修改，删除）
		const operatorAndNDCsSingle = "operatorAndNDCs/single"; //查询运营商下的号段
		
		/* 运营商接口 */
		const operators = "operators"; //根据条件查询运营商（添加）
		const operatorsID = "operators/" + id; //根据id查询运营商（修改，删除）
		const operatorsOperatorIdMNC = "operators/" + id + "/mnc"; //根据运营商查询MNC
		const operatorsSingle = "operators/single"; //国家运营商信息table
		const operatorsSearchData = "operators/searchData"; //根据国家id查询运营商信息
		
		/* 通道价格接口 */
		const channelPrice = "channelPrice"; //同步（添加，修改）
		const channelPriceDeleteBatch = "channelPrice/deleteBatch"; //批量删除
		const channelPriceExport = "channelPrice/export:" + id; //通道价格导出
		const channelPriceImport = "channelPrice/import:" + id; //通道价格导入
		const channelPriceList = "channelPrice/list"; //根据条件查询通道价格
		
		/* 通道价格更改记录接口 */
		const logChannelPriceChanges = "logChannelPriceChanges"; //查询通道价格更改记录
		
		/* 通道价格邮件日志接口 */
		const logMailSuppliersIdPrice = "logMailSuppliers/" + id + "/price"; //根据id查询通道价格邮件日志
		const logMailSuppliersDownloadID = "logMailSuppliers/download/" + id; //下载文件
		const logMailSuppliersList = "logMailSuppliers/list"; //查询通道价格邮箱日志
		const logMailSuppliersreImportID = "logMailSuppliersreImport/" + id; //重新导入
		
		/* 通道管理接口 */
		const channels = "channels"; //根据条件查询通道（添加）
		const channelsID = "channels/" + id; //根基id查询通道（修改，删除）
		const channelsAll = "channels/all"; //查询通道信息（无需查询队列消息数）
		const channelOperatorsID = id + "/channelOperators"; //通道运营商添加（批量添加）
		const channelsConnectStatusID = "channels/connectStatus/" + id; //查询通道连接状态
		const channelsImport = "channels/import"; //通道导入
		
		/* 通道运营商接口 */
		const idchannelOperators = id + "/channelOperators"; //添加
		const channelOperatorsDeleteBatch = id + "/channelOperators/deleteBatch"; //删除
		const channelOperators = "channelOperators"; //根据条件查询通道运营商
		
		/* 验证码接口*/
		const vcjpg = "vc.jpg"; //验证码
		
		const SystemSystemInfo = "System/systemInfo"; //获取系统信息（CPU、磁盘......）
		const menu = "menu";
		const menuID = "menu/" + id;

		return {
			excelFormat,
			excelFormatID,
			logUserRegionPriceList,
			logChannelPriceCost,
			logChannelPriceCostCost,
			logChannelPriceCostExport,
			sendRecord,
			regionPriceExportDownload,
			regionPriceExportExport,
			regionPriceExportImport,
			regionPrice,
			regionPriceList,
			regionPriceRefresh,
			regionPriceID,
			regionPricePlanIdPreviewMail,
			regionPricePlanIdSelectSendMail,
			regionPricePlanIdSendMail,
			regionPriceAllPlanRegionPrice,
			regionPriceDeleteBatch,
			region,
			regionExport,
			regionImport,
			regionRefresh,
			regionID,
			regionRegionIdMCC,
			regionNoPrice,
			regionPricePlan,
			regionPricePlanList,
			regionPricePlanID,
			regionPricePlanCurrency,
			timingTaskRegionPrices,
			timingTaskRegionPricesID,
			timingTaskRegionPricesGetDetailsById,
			userAndRegionPrice,
			userAndRegionPriceList,
			userAndRegionPriceID,
			group,
			groupList,
			groupID,
			groups,
			billDetails,
			billDetailsCost,
			billDetailsExport,
			logCustomerPriceCost,
			logCustomerPriceCostCost,
			logCustomerPriceCostExport,
			userRechargeLog,
			groupUsers,
			user,
			userID,
			userPassword,
			userRecharge,
			userRechargePassword,
			usersID,
			userChannels,
			userChannelsRefresh,
			userChannelsID,
			userChannelsDeletes,
			userChannelsGetUserConnectedRegionID,
			userChannelsGetUserNotConnectedRegionID,
			userChannelsUserChannelId,
			version,
			login,
			logout,
			tokenRefresh,
			account,
			accountID,
			accountPassword,
			accountReset,
			currencyExchanges,
			currencyExchangesID,
			currency,
			currencyID,
			operatorAndNDCs,
			operatorAndNDCsID,
			operatorAndNDCsSingle,
			operators,
			operatorsID,
			operatorsOperatorIdMNC,
			operatorsSingle,
			operatorsSearchData,
			channelPrice,
			channelPriceDeleteBatch,
			channelPriceExport,
			channelPriceImport,
			channelPriceList,
			logChannelPriceChanges,
			logMailSuppliersIdPrice,
			logMailSuppliersDownloadID,
			logMailSuppliersList,
			logMailSuppliersreImportID,
			channels,
			channelsID,
			channelsAll,
			channelOperatorsID,
			channelsConnectStatusID,
			channelsImport,
			idchannelOperators,
			channelOperatorsDeleteBatch,
			channelOperators,
			vcjpg,
			SystemSystemInfo,
			menu,
			menuID
		}
	}
}

export default urls
